<template>
  <div>
    <div class="row">
      <div class="col-lg-3 mb-7">
        <router-link to="/order">
          <div class="bg-white border px-6 py-8 rounded-sm">
            <h3 class="text-dark fw-600">
              Orders
            </h3>
            <span class="text-dark">
              0 Orders
            </span>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3 mb-7">
        <router-link to="/return">
          <div class="bg-white border px-6 py-8 rounded-sm">
            <h3 class="text-dark fw-600">
              Returns
            </h3>
            <span class="text-dark">
              0 return
            </span>
          </div>
        </router-link>
      </div>

      <div class="col-lg-3 mb-7">
        <router-link to="/cancellation">
          <div class="bg-white border px-6 py-8 rounded-sm">
            <h3 class="text-dark fw-600">
              Cancellations
            </h3>
            <span class="text-dark">
              0 cancellations
            </span>
          </div>
        </router-link>
      </div>

      <div class="col-lg-3 mb-7">
        <router-link to="/review">
          <div class="bg-white border px-6 py-8 rounded-sm">
            <h3 class="text-dark fw-600">
              Review
            </h3>
            <span class="text-dark">
              0 Orders
            </span>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3 mb-7">
        <router-link to="/products">
          <div class="bg-white border px-6 py-8 rounded-sm">
            <h3 class="text-dark fw-600">
              Products
            </h3>
            <span class="text-dark">
              1200 Products
            </span>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3 mb-7">
        <router-link to="/users">
          <div class="bg-white border px-6 py-8 rounded-sm">
            <h3 class="text-dark fw-600">
              New users
            </h3>
            <span class="text-dark">
              13000 Users
            </span>
          </div>
        </router-link>
      </div>
      <div class="col-lg-3 mb-7">
        <router-link to="/contact">
          <div class="bg-white border px-6 py-8 rounded-sm">
            <h3 class="text-dark fw-600">
                  Contacts
            </h3>
                <span class="text-dark">
                  10 Contacts
                </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

  export default {
    name: "dashboard",
    components: {},
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    },
    methods: {},
  };
</script>
